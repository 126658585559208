// store.js
import { configureStore } from '@reduxjs/toolkit'
import toggleButton from '../redux-slicer/toggleButton'
import activeCanvasReducer from '../redux-slicer/activeCanvas'


export default configureStore({
    reducer: {
        toggleButton: toggleButton,
        activeCanvas: activeCanvasReducer,
    },
})
