import { createSlice } from '@reduxjs/toolkit'

export const activateCanvasReducer = createSlice({
  name: 'canvasId',
  initialState: {
    value: '.hightlightCanvas',
  },
  reducers: {
    activateCanvas: (state, action) => {
      let activatedCanvases = document.querySelectorAll('.activeCanvas');
      if (activatedCanvases.length > 0) {
        activatedCanvases.forEach(canvas => {
          canvas.classList.remove('activeCanvas');
        })
      }
      state.value = action.payload
      let activateCanvases = document.querySelectorAll(state.value);

      activateCanvases.forEach(canvas => {
        canvas.classList.add('activeCanvas');
      })
    },
    deactivateCanvas: (state, action) => {
     

    }

  },
})

// Action creators are generated for each case reducer function
export const { activateCanvas, deactivateCanvas } = activateCanvasReducer.actions

export default activateCanvasReducer.reducer;