import React, { useEffect } from "react";
import { useSelector } from 'react-redux'

function Measure({ page, pageNumber, scale, vwidth, vheight }) {

    const state = useSelector((state) => state.toggleButton.measureBtn.active);

    useEffect(() => {
        Measure();
    }, [state])
    const drawRuler = (canvas, startX, startY, x, y) => {
        const ctx = canvas.getContext('2d');
        ctx.strokeStyle = 'red'; // Replace 'red' with the desired color

        // Set the text color
        ctx.fillStyle = 'blue';

        const textX = (startX + x) / 2;
        const textY = (startY + y) / 2;
        let lengthOfLine = x - textX
        if (lengthOfLine < -1) {
            console.log(lengthOfLine * -1);
            lengthOfLine = lengthOfLine * -1
        }
        // Clear the entire canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw a straight line from the starting point to the current mouse position
        ctx.fillText(lengthOfLine, textX, textY);
        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(x, y);
        ctx.stroke();
    }
    const Measure = () => {
        const canvas = document.querySelector('#measure-canvas1')

        let isDrawing = false;
        let startX, startY;

        const handleMousedown = (e) => {
            isDrawing = true;
            startX = e.clientX - canvas.getBoundingClientRect().left;
            startY = e.clientY - canvas.getBoundingClientRect().top;
        }
        const handleMouseMove = (e) => {
            if (isDrawing) {
                const x = e.clientX - canvas.getBoundingClientRect().left;
                const y = e.clientY - canvas.getBoundingClientRect().top;
                drawRuler(canvas, startX, startY, x, y)

            }
        }

        const handleMouseUp = () => {
            isDrawing = false;
        }

        canvas.removeEventListener('mousedown', handleMousedown);
        canvas.removeEventListener('mousemove', handleMouseMove);
        canvas.removeEventListener('mouseup', handleMouseUp);

        if (state) {
            canvas.addEventListener('mousedown', handleMousedown);
            canvas.addEventListener('mousemove', handleMouseMove);
            canvas.addEventListener('mouseup', handleMouseUp);  
        }
        
    }

    return (
        <canvas className="measureCanvas" id={`measure-canvas1`} height={vheight} width={vwidth}></canvas>

    )

}
export default Measure;