import React, { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import PdfRenderer from '../PdfRenderer';
import './style.css';
import Controls from '../Controls'
import { useSelector } from 'react-redux'


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

function PdfViewer() {
    const [page, setPage] = useState(null);
    const [activePageNumber, setActivePageNumber] = useState(1);
    const scale = 2;

    
    useEffect(() => {
        // Load the PDF document
        pdfjs.getDocument('/demo.pdf').promise.then(pdf => {

            // Load the first page
            pdf.getPage(1).then(initialPage => {
                setPage(initialPage);
            });
        });
    },[] );
    
    
    

    return (
        <>
            <Controls/>
            {page && (

                <PdfRenderer
                    page={page}
                    pageNumber={activePageNumber}
                    scale={scale}
                />
            )}
        </>
    );
}

export default PdfViewer;
