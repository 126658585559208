import logo from './logo.svg';
import './App.css';
import PdfViewer from './components/pdfViewer';

function App() {
  return (
    <div className="App">
      <PdfViewer />
    </div>
  );
}

export default App;
