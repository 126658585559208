import React, { useEffect, useState } from 'react';
import './style.css'
import HighlightAnnotation from '../Annotations/HighlightAnnotation';
import Measure from '../Annotations/Measure';

function PdfRenderer({ page, pageNumber, scale }) {

    const [viewport, setViewport] = useState(null)
    const [vDimensions, setVDimensions] = useState({ height: 500, width: 500 })


    useEffect(() => {

        if (viewport === null) {
            setViewport(page.getViewport({ scale: 2, rotation: 360, height: 500, width: 500 }));
        }
        if(viewport !== null) {
            pdfRender();
        }
        

    }, [viewport])

    const pdfRender = () => {
        const canvas = document.createElement('canvas');
        canvas.height = viewport?.height
        canvas.width = viewport?.width;
        canvas.classList.add('pdfCanvas');
        const context = canvas.getContext('2d');
        document.querySelector('.canvasContainer').prepend(canvas);
        page.render({ canvasContext: context, viewport: viewport });
    }

    return (
        <>

            <div className="canvasContainer">

                <HighlightAnnotation
                    page={page}
                    pageNumber={pageNumber}
                    scale={scale}
                    vheight={viewport?.height}
                    vwidth={viewport?.width}

                />
                <Measure
                    page={page}
                    pageNumber={pageNumber}
                    scale={scale}
                    vheight={viewport?.height}
                    vwidth={viewport?.width}

                />
            </div>



        </>
    );
}
export default PdfRenderer;