import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { toggleButton } from '../../../redux-slicer/toggleButton'
import { activateCanvas, deactivateCanvas } from "../../../redux-slicer/activeCanvas";
import { } from '../../../store/store'
import '../style.css'


function HighlightButton() {
    const btn = useSelector((state) => state.toggleButton.highlightBtn);
    const btnText = btn.text
    const [state, setState] = useState(btn.active);
    const dispatch = useDispatch()
    useEffect(() => {
        setState(!state);
        if (state) {
            dispatch(activateCanvas('.hightlightCanvas'))
        }
        
    }, [btn]);
    return (
        <>
        
            <button className="highlight-btn control-btn" onClick={() => {
                dispatch(toggleButton('RESET_ALL_BUTTONS'))
                dispatch(toggleButton('highlight'))
                
            }}>
                {btnText}
            </button>
        </>
    );
}
export default HighlightButton