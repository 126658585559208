import React, { useCallback, useEffect, useState } from 'react';
import './style.css'
import { useSelector } from 'react-redux'

function HighlightAnnotation({ page, pageNumber, scale, vwidth, vheight }) {

  const btn = useSelector((state) => state.toggleButton.highlightBtn)
  const [canvas, setCanvas] = useState('');
  const state = btn.active;
  const stroke = {
    color: 'yellow',
    opacity: '0.5'
  }
  useEffect(() => {
    setCanvas(document.querySelector('#highlight-canvas1'));
    highlightText();
    
  }, [btn])

  const handleMouseMove = useCallback((e) => {
    // Get the mouse coordinates relative to the overlay canvas
    const mouseX = e.clientX - canvas.getBoundingClientRect().left;
    const mouseY = e.clientY - canvas.getBoundingClientRect().top;
    // Convert mouse coordinates to PDF page coordinates
    const pdfCoordinates = page.getViewport({ scale: scale }).convertToPdfPoint(mouseX, mouseY);

    // Identify text under the mouse pointer
    page.getTextContent().then((textContent) => {
      for (let i = 0; i < textContent.items.length; i++) {
        const item = textContent.items[i];
        if (
          pdfCoordinates[0] >= item.transform[4] &&
          pdfCoordinates[0] <= item.transform[4] + item.width &&
          pdfCoordinates[1] >= item.transform[5] - item.height &&
          pdfCoordinates[1] <= item.transform[5]
        ) {
          // Calculate the position and dimensions of the highlighted area
          const x = mouseX;
          const y = mouseY - 2;
          const width = 1;
          const height = item.height - 1;
          const ctx = canvas.getContext('2d');
          ctx.fillStyle = stroke.color;
          ctx.globalAlpha = stroke.opacity;
          ctx.fillRect(x, y, width, height);

        }
      }
    });


  }, [canvas]  )
  const highlightText = () => {

    
    if (state) {
      canvas?.addEventListener('mousemove', handleMouseMove);
      return;
    } 
    if(canvas) canvas?.removeEventListener('mousemove', handleMouseMove);

  }

  return (
    <>
      <canvas className='hightlightCanvas' id={`highlight-canvas1`} height={vheight} width={vwidth}></canvas>
    </>
  );
}

export default HighlightAnnotation;
