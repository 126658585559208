import React from "react";
import './style.css'
import HighlightButton from './HighlightButton';
import MeasureButton from "./MeasureButton";
function Controls() {
return(
    <>
    <div className="controlsContainer">
        <div className="control">
            <HighlightButton/>
            <MeasureButton/>
        </div>
    </div>
    </>
)

}
export default Controls;