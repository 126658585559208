import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  highlightBtn: { active: false, text: 'Highlight' },
  measureBtn: { value: false, text: 'Measure' },
}

export const toggleButtonReducer = createSlice({
  name: 'toggle',
  initialState: initialState,
  reducers: {
    toggleButton: (state = initialState, action) => {
      switch (action.payload) {
        case 'highlight':
          return {
            ...state,
            highlightBtn: {
              active: !state.highlightBtn.active,
              text: !state.highlightBtn.active ? 'Stop Highlighting' : 'Highlight',
            },
          }

        case 'measure':
          return {
            ...state,
            measureBtn: {
              active: !state.measureBtn.active,
              text: !state.measureBtn.active ? 'Stop Measuring' : 'Measure',
            },
          }

        case 'RESET_ALL_BUTTONS':
          return initialState;

        default:
          return state;
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { toggleButton } = toggleButtonReducer.actions

export default toggleButtonReducer.reducer